import * as React from "react"
import { Container, Row, Col } from "reactstrap"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Slider from "react-slick"

import activerse from '../images/activerse_logo.svg'
import dietlabs from '../images/dietlabs_logo.svg'
import cukrzyca from '../images/cukrzyca_logo.svg'
import asystent from '../images/asystent_cukrzyca_logo.svg'
import wellwithdiabetes from '../images/well_with_diabetes_logo.svg'
import mamhashi from '../images/mamhashi_logo.svg'
import arrowRight from '../images/arrow_right.svg'
import researchGate from '../images/research_gate.svg'

const IndexPage = () => {
    const slider = React.createRef()

    const handlePrev = () => {
        slider.current.slickPrev()
    }

    const handleNext = () => {
        slider.current.slickNext()
    }

    const settings = {
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 0,
        dots: true,
        speed: 500,
        arrows: false,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                    arrows: false,
                    appendDots: dots => {
                        return (
                            <div>
                                <button className="slider-btn prev" onClick={handlePrev}>
                                    <img src={arrowRight} className="rotate180deg" alt="prev" />
                                </button>
                                <ul style={{ margin: "0px" }}> {dots} </ul>
                                <button className="slider-btn next" onClick={handleNext}>
                                    <img src={arrowRight} alt="next" />
                                </button>
                            </div>
                        )
                    },
                },
            },
        ],
    }

    return (
        <Layout>
            <Seo title="Home" />
    
            <section className="bg-gradient">
                <Container className="text-center">
                    <Row className="justify-content-center">
                        <Col md="7">
                            <h1 className="heading-3">Healthier &amp; Wiser</h1>
                            <p className="big mb-0">Tworzymy aplikacje mobilne i głosowe wspierające w zakresie wellbeing (dieta, fitness, motywacja, sen, balans) osoby chorujące na cukrzycę i Hashimoto.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section>
                <Container>
                    <Row className="justify-content-center">
                        <Col md="10">
                            <p className="big text-center"><strong>DietLabs MedTech sp. z o.o.</strong> to spółka zależna <strong>activerse.app sp. z o.o.</strong> (wcześniej DietLabs sp. z o.o.). O&nbsp;projektach realizowanych przez spółkę activerse.app przeczytasz <a href="https://activerse.app/">TUTAJ</a></p>
    
                            <StaticImage
                                src="../images/dietlabs_img.jpeg"
                                quality={95}
                                alt="Dietlabs"
                                className="mb-4"
                            />
    
                            <p className="big text-center">Pragniemy poprawić jakość życia osób zmagających się z cukrzycą lub Hashimoto. Jesteśmy właścicielem serwisów: <a href="https://cukrzyca.pl/">cukrzyca.pl</a>, <a href="https://mamhashi.pl/">mamhashi.pl</a>, aplikacji <a href="https://aplikacja.cukrzyca.pl/app">aplikacja.cukrzyca.pl/app</a> oraz <a href="https://aplikacja.mamhashi.pl/app">aplikacja.mamhashi.pl/app</a>.</p>
    
                            <p className="big text-center mb-0"> 
                            W czwartym kwartale 2023 roku premierę miały dwie aplikacje mobilne: <a href="https://cukrzyca.onelink.me/CaaA/sf3thyv7">Cukrzyca.pl</a> oraz <a href="https://mamhashi.onelink.me/Z7V0/aqdnyisj">Mamhashi</a>. 
                            Spółka korzysta z technologii i procesów wypracowanych przez spółkę matkę - <strong>activerse.app</strong>.</p>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <section className="py-0">
                <Container className="text-center">
                    <div className="did-you-know">
                        <Row>
                            <Col md={{size: 8, offset: 2}}>
                                <h3>Czy wiesz że...</h3>
                                <p className="big">W USA na każde 100$ produktu krajowego brutto 17,9$ <span className="blue">pochłania opieka zdrowotna</span> i wszelkie aspekty, które są z nią związane. Natomiast <span className="blue">80%</span> wydatków to problemy wynikające ze <span className="blue">złej diety</span>.</p>
                                <p className="small mb-0 opacity-75">Źródło: http://www.sterlingheritage.com/ services_shs/services_shs_publichealth.html</p>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
    
            <section id="team">
                <Container>
                    <h2 className="text-center">Zespół</h2>
                    <p className="big text-center">Nad merytoryką projektu oraz treściami w serwisach cukrzyca.pl i mamhashi.pl czuwają:</p>

                    <div className="slider-container">
                        <Slider ref={slider} {...settings}>
                            <div>
                                <div className="team-member">
                                    <StaticImage
                                        src="../images/Anita-Rogowicz-Frontczak.jpeg"
                                        quality={95}
                                        alt="Anita Rogowicz Frontczak"
                                        className="team-member-photo"
                                        width={146}
                                    />
                                    <h4 className="mx-minus">Anita Rogowicz-Frontczak</h4>
                                    <p>dr n. med.</p>
                                    <div className="white-box">
                                        <p className="mb-0">Diabetolog, endokrynolog, specjalista chorób wewnętrznych. Adiunkt w Klinice Chorób Wewnętrznych i Diabetologii Uniwersytetu Medycznego w Poznaniu. Autorka licznych publikacji naukowych w dziedzinie diabetologii i endokrynologii.</p>
                                    </div>
                                    <a className="rg" href="https://www.researchgate.net/profile/Anita-Rogowicz-Frontczak">
                                        <img src={researchGate} alt="Research Gate" />
                                    </a>
                                </div>
                            </div>

                            <div>
                                <div className="team-member">
                                    <StaticImage
                                        src="../images/Marta-Banaszak.jpeg"
                                        quality={95}
                                        alt="Marta Banaszak"
                                        className="team-member-photo"
                                        width={146}
                                    />
                                    <h4>Marta Banaszak</h4>
                                    <p>mgr</p>
                                    <div className="white-box">
                                        <p className="mb-0">Dietetyk kliniczny, absolwentka dietetyki na Uniwersytecie Medycznym w Poznaniu. Edukator żywieniowy ogólnopolskiego programu “Zdrowo jemy, zdrowo rośniemy”. Posiada bogate doświadczenie kliniczne z zakresu żywienia człowieka.</p>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <div className="team-member">
                                    <StaticImage
                                        src="../images/Joanna-Wisniewska.jpeg"
                                        quality={95}
                                        alt="Joanna Wiśniewska"
                                        className="team-member-photo"
                                        width={146}
                                    />
                                    <h4>Joanna Wiśniewska</h4>
                                    <p>mgr</p>
                                    <div className="white-box">
                                        <p className="mb-0">Absolwentka Uniwersytetu im. Adama Mickiewicza w Poznaniu kierunku Kognitywistyka. Zainteresowana wpływem emocji na motywacje ludzkie oraz kondycją psychiki człowieka. W pracy wykorzystuje zdobytą o człowieku wiedzę.</p>
                                    </div>
                                </div>
                            </div>
                        </Slider>
                    </div>
                </Container>
            </section>
    
            <section className="bg-gradient">
                <Container>
                    <div className="opacity-box text-center">
                        <img src={activerse} className="mb-5 img-fluid" alt="activerse" />
                        <div className="white-box" data-text="activerse.app sp. z o.o. posiada 80% udziałów DietLabs MedTech sp. z o.o.">
                            <img src={dietlabs} alt="dietlabs" />
                            <p className="big mt-4">DietLabs MedTech sp. z o.o.</p>
                            <Row>
                                <Col md="3"><img src={cukrzyca} className="img-fluid mb-3 mb-md-0" alt="cukrzyca" /></Col>
                                <Col md="3"><img src={asystent} className="img-fluid mb-3 mb-md-0" alt="asystent" /></Col>
                                <Col md="3"><img src={wellwithdiabetes} className="img-fluid mb-3 mb-md-0" alt="wellwithdiabetes" /></Col>
                                <Col md="3"><img src={mamhashi} className="img-fluid" alt="mamhashi" /></Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </section>
    
            <section id="fund">
                <Container>
                    <h2 className="text-center">Fundusze norweskie</h2>
                    <Row>
                        <Col md="2" className="text-center text-md-start">
                            <StaticImage
                                src="../images/norway_grants_logo.png"
                                quality={95}
                                width={128}
                                alt="Dietlabs"
                                className="me-4 mb-5"
                            />
    
                            <StaticImage
                                src="../images/parp_logo.png"
                                quality={95}
                                width={128}
                                alt="Dietlabs"
                                className="mt-5"
                            />
    
                        </Col>
                        <Col md="10">
                            <p>Beneficjent DIETLABS MEDTECH SPÓŁKA Z OGRANICZONĄ ODPOWIEDZIALNOŚCIĄ realizujący projekt nr NORW.19.01.04-30-0002/20 pn. „Opracowanie i wdrożenie innowacyjnych aplikacji mobilnych wspierających osoby chorujące na cukrzycę i Hashimoto” korzysta z dofinansowania o wartości 800 920.00 PLN (186 572,87 EUR) otrzymanego od Norwegii. Całkowita wartość projektu to 2 038 848.00 PLN.</p>
                            <p>Realizacja projektu przewiduje opracowanie i wdrożenie innowacyjnych aplikacji mobilnych, które będą wspierać osoby chorujące na cukrzycę i chorobę Hashimoto. System będzie dostarczał wiele innowacyjnych funkcjonalności dla wyżej wymienionych grup docelowych - możliwość generowania spersonalizowanego planu diety, moduły motywacyjne poprawiające poziom selfmanagement. W ten sposób Wnioskodawca planuje wesprzeć i poprawić jakość życia osób cierpiących z powodu tych chorób i dostarczyć im holistyczne narzędzie, którego jeszcze nikt nie wprowadził na rynek. Drugim celem przedsięwzięcia jest wzrost wiedzy, umiejętności i kompetencji przedsiębiorcy, a także rozwój firmy poprzez sprzedaż opracowanego produktu i osiąganie w ten sposób przychodów oraz zysków</p>
                            <p>Fundusze EOG i fundusze norweskie - Wspólnie działamy na rzecz Europy zielonej, konkurencyjnej i sprzyjającej integracji społecznej. <br />
                            <a href="https://eeagrants.org/">www.eeagrants.org</a></p>
                            <p>Fundusze norweskie i fundusze EOG reprezentują wkład Norwegii w tworzenie Europy zielonej, konkurencyjnej i sprzyjającej integracji społecznej.<br />
                            W ramach funduszy norweskich i funduszy EOG Norwegia przyczynia się do ograniczenia nierówności społecznych i ekonomicznych oraz wzmocnienia relacji dwustronnych z państwami beneficjentami z Europy Środkowej i Południowej i obszaru Morza Bałtyckiego. Norwegia ściśle współpracuje z UE w ramach Porozumienia o Europejskim Obszarze Gospodarczym (EOG). Wraz z pozostałymi darczyńcami Norwegia przekazała 3,3 miliarda euro w ramach kolejnych programów funduszy w latach 1994-2014.</p>
                            <p>Fundusze norweskie są finansowane wyłącznie przez Norwegię i dostępne w państwach, które przystąpiły do UE po 2003 r. Fundusze norweskie na lata 2014-2021 wynoszą 1,25 miliarda euro. Priorytety na ten okres to:</p>
                            <ul className="list mb-0">
                                <li>1 innowacje, badania naukowe, edukacja, konkurencyjność i godna praca;</li>
                                <li>2 integracja społeczna, zatrudnienie młodzieży i ograniczenie ubóstwa;</li>
                                <li>3 środowisko, energia, zmiany klimatu i gospodarka niskoemisyjna;</li>
                                <li>4 kultura, społeczeństwo obywatelskie, dobre zarządzanie i podstawowe prawa;</li>
                                <li>5 sprawiedliwość i sprawy wewnętrzne.</li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
    
            <Container>
                <hr className="my-0" />
            </Container>
            
            <section id="contact">
                <Container className="text-center text-md-start">
                    <h2 className="text-center">Kontakt</h2>
                    <p><strong>DietLabs MedTech sp. z o.o.</strong></p>
                    <Row>
                        <Col md="4">
                            
                            <p>
                                ul. Głogowska 31/33<br/>
                                60-702 Poznań<br/>
                                Poland
                            </p>
                        </Col>
                        <Col md="4" className="text-center">
                            <p>
                                EU VAT: PL7792514378<br/>
                                KRS: 0000835159<br/>
                                REGON: 385832009
                            </p>
                        </Col>
                        <Col md="4">
                            <p className="text-md-end">
                                Napisz do nas:<br/>
                                <a className="footer-link" href="mailto:biuro@dietlabs.pl">biuro@dietlabs.pl</a>
                            </p>

                            <p className="text-md-end">
                                Inspektor Ochrony Danych:<br/>
                                Kamila Knasiecka<br/>
                                <a className="footer-link" href="mailto:dpo@dietlabs.pl">dpo@dietlabs.pl</a>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default IndexPage
